@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-apricot-title;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .h-screen {
    height: 100vh;
    height: 100dvh;
    height: 100svh;
  }

  .min-h-screen {
    min-height: 100vh;
    min-height: 100dvh;
    min-height: 100svh;
  }

  .h-loose-nav {
    height: calc(100vh - 8rem);
    height: calc(100dvh - 8rem);
    height: calc(100svh - 8rem);
  }

  .h-tight-nav {
    height: calc(100vh - 4.5rem);
    height: calc(100dvh - 4.5rem);
    height: calc(100svh - 4.5rem);
  }

  .overflow-clip {
    overflow: hidden;
    overflow: clip;
  }

  .overflow0-x-clip {
    overflow-x: hidden;
    overflow-x: clip;
  }

  /* .text-2xs {
    font-size: 0.625rem;
    line-height: 0.75rem;
  } */

  /* .aesthetic-shadow-md {
    --tw-aesthetic-shadow: drop-shadow(0 1.5px 3px rgb(0, 0, 0, 0.2));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-aesthetic-shadow);
  }

  .aesthetic-shadow-lg {
    --tw-aesthetic-shadow: drop-shadow(0 2px 6px rgb(0, 0, 0, 0.2));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-aesthetic-shadow);
  } */
}
